<template>
  <div class="modal" v-show="visible">
    <div class="modal-header">
      {{ title }}
    </div>
    <div class="modal-content">
      <img v-if="modalIcon" :src="modalIcon" alt="" />
      <slot>
        <p v-if="!dangerouslyUseHTMLString">
          {{ content }}
        </p>
        <p v-else v-html="content"></p>
      </slot>
    </div>
    <div class="modal-input" v-if="input">
      <input :placeholder="placeholder" v-model="message" />
    </div>
    <div class="modal-footer">
      <!--//0默认，1需要取消不要确认按钮，2需要确认不要取消按钮-->
      <div @click="close" v-if="optionControl!=2">{{ closeText }}</div>
      <div class="confirm" @click="confirm" v-if="optionControl!=1">{{ confirmText }}</div>
    </div>
  </div>
  <div v-show="visible" class="toast-modal"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import Toast from "../../Toast";

type toastType = "success" | "error" | "warning" | "none";

export default defineComponent({
  name: "Modal",
  props: {
    type: {
      type: String as PropType<toastType>,
      default: "none",
    },
    optionControl: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "",
    },
    dangerouslyUseHTMLString: { type: Boolean, default: false },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    modal: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 1500,
    },
    onClose: Function,
    onCancel: Function,
    onConfirm: Function,

    closeText: {
      type: String,
      default: "取消",
    },
    confirmText: {
      type: String,
      default: "确认",
    },
    placeholder: {
      type: String,
      default: "",
    },
    input:{
 type: Boolean,
      default: false,
    },
    inputPattern: {
      type: RegExp,
    },
    inputErrorMessage: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const toastType = {
      success: require("./imgs/success.png"),
      error: require("./imgs/error.png"),
      warning: require("./imgs/warning.png"),
      none: "",
    };
    const closed = ref(false);
    const visible = ref(false);
    const message = ref("");
    const modalIcon = props.icon ? props.icon : toastType[props.type];
    function destoryElement() {
      visible.value = false;
      document.body.style.overflow = "auto";
      if (props.onClose) {
        (props.onClose as () => void)();
      }
    }
    watch(closed, (newVal) => {
      if (newVal) {
        destoryElement();
      }
    });
    onMounted(() => {
      visible.value = true;
    });

    function close() {
      if(props.onCancel){
         (props.onCancel as () => void)();
      }
      closed.value = true;
    }
    function confirm() {
      if (props.onConfirm) {
        if (props.inputPattern) {
          if (props.inputPattern.test(message.value)) {
            (props.onConfirm as (args?: any) => void)(message.value);
          } 
          else {
            Toast({
              title: props.inputErrorMessage,
              type: "warning",
            });
            return false;
          }
        }else{
          props.onConfirm()
        }
        
      }
     
       closed.value = true;
    }


    return {
      modalIcon,
      close,
      visible,
      confirm,
      message,
    };
  },
});
</script>

<style lang="scss" scoped>
.toast-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  color: #333;
  padding: 10px 20px;
  min-height: 50px;
  max-width: 340px;
  width: 80%;
  font-size: 14px;
  box-sizing: border-box;
  .modal-header {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
  }
  .modal-content {
    padding: 15px 0;
    line-height: 24px;
    text-align: center;
  }
  .modal-footer {
    display: flex;
    line-height: 40px;
    div {
      flex: 1;
      text-align: center;
    }
    .confirm {
      color: #df0024;
    }
  }
  img {
    width: 30px;
    height: 30px;

    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
  }
}
.modal-input input {
  border: none;
  line-height: 44px;
  width: 100%;
  display: block;
  margin: 5px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding-left: 5px;
  box-sizing: border-box;
}
</style>

