
/**
 *  节流函数
 * @param func
 * @param wait time
 */

export function throttle(func: () => void, wait: number, immediate?: boolean) {
    let timer: any = null;
    let first = true


    return function (...args: any[]) {

        if (immediate) {
            if (first) {
                func.apply(this, args as [])
                first = false
            }
        } else {
            if (timer) {
                return false
            }
        }
        timer = setTimeout(() => {
            func.apply(this, args as [])
            clearTimeout(timer)
            timer = null
        }, wait);


    }
}


export function parthrottle(func: (e?: any) => void, wait: number, immediate?: boolean) {
    let timer: any = null;
    let first = true


    return function (...args: any[]) {

        if (immediate) {
            if (first) {
                func.apply(this, args as [])
                first = false
            }
        } else {
            if (timer) {
                return false
            }
        }
        timer = setTimeout(() => {
            func.apply(this, args as [])
            clearTimeout(timer)
            timer = null
        }, wait);


    }
}
